<template>
  <div class="main-content white">
    <div class="edit-msg">
      <div class="input-wrap">
        <VueEditor
          v-model="settings.remark"
          :editorOptions="editorSettings"
          :editorToolbar="customToolbar"
          @image-added="handleImageAdded"
          :use-custom-image-handler="true"
        ></VueEditor>
      </div>
    </div>
  </div>
</template>

<script>
import memberSettingsMixin from '@/mixins/member-settings';
import { VueEditor } from 'vue2-editor';
import EditorMixin from '@/mixins/vue2-editor';
import { debounce } from 'lodash';

const defaultRemark = '如有下單請務必加入開團主好友，以免收不到通知訊息';

const defaultSetting = () => {
  return {
    remark: defaultRemark
  };
};

export default {
  mixins: [memberSettingsMixin, EditorMixin],
  components: {
    VueEditor
  },
  data: function() {
    return {
      key: 'default_create_group_settings', //團主開團說明
      settings: defaultSetting
    };
  },
  methods: {
    getDataSuccessCallback(data) {
      this.settings = data;
    }
  },
  watch: {
    settings: {
      handler: debounce(function(newVal, oldVal) {
        if (this.noSettingYet) {
          this.settings = defaultSetting;
          return;
        }

        if (oldVal !== defaultSetting) {
          this.updateSettings();
        }
      }, 500),
      deep: true
    }
  }
};
</script>
